import { type WordpressProductSelection } from 'libs/wordpress/types/contentSystem/groups';

export interface AlgoliaProductSelection {
    default: string[][];
    selected: string[][];
    dynamic: string[][];
} // @TODO Should be owned by Algolia/ProductsContainer?

export const transformWordpressProductSelectionToAlgoliaParameters = (
    selection: WordpressProductSelection,
): AlgoliaProductSelection => {
    const filters: AlgoliaProductSelection = {
        default: [],
        selected: [],
        dynamic: [],
    };
    const selectionObject = selection[0];
    const { layout } = selectionObject;

    if (layout === 'norce_category') {
        const { category } = selectionObject;
        filters.default.push([`categories.code:"${category}"`]);
    }

    if (layout === 'norce_products') {
        const { products } = selectionObject;
        filters.default.push(products.map(({ product: { product: id } }) => `objectID:"${id}"`));
    }

    if (layout === 'norce_advanced_selection') {
        const { advancedProductsSelection } = selectionObject;

        if (advancedProductsSelection.categories) {
            filters.default.push(advancedProductsSelection.categories.map((c) => `categories.code:"${c}"`));
        }

        if (advancedProductsSelection.manufacturers) {
            filters.default.push(advancedProductsSelection.manufacturers.map((m) => `manufacturer.code:"${m}"`));
        }
        if (advancedProductsSelection.parametrics) {
            advancedProductsSelection.parametrics.forEach((p) => {
                switch (p.type) {
                    case 'codes':
                        return (
                            p.codes &&
                            filters.default.push(
                                p.codes.map((c) => `parametrics.${p.parametric}.value.code:"${c.code}"`),
                            )
                        );
                    case 'values':
                        return (
                            p.values &&
                            filters.default.push(
                                p.values.map((v) => `parametrics.${p.parametric}.value.value:"${v.value}"`),
                            )
                        );
                    case 'range':
                        return filters.default.push([`parametrics.${p.parametric}.value.value: ${p.min} TO ${p.max}`]);
                    default:
                        break;
                }
            });
        }

        if (advancedProductsSelection.flags) {
            filters.default.push(advancedProductsSelection.flags.map((f) => `flags.code:"${f}"`));
        }
    }

    // TODO: This should be implemented in the CMS

    // if (selection.price) {
    //     const pricingIncVatString = 'pricing.pricelists.web.incVat';

    //     if (selection.price.min && selection.price.max) {
    //         filters.default.push([`${pricingIncVatString}: ${selection.price.min} TO ${selection.price.max}`]);
    //     } else {
    //         if (selection.price.min) {
    //             filters.default.push([`${pricingIncVatString} > ${selection.price.min}`]);
    //         }

    //         if (selection.price.max) {
    //             filters.default.push([`${pricingIncVatString} < ${selection.price.max}`]);
    //         }
    //     }
    // }

    // if (selection.pricelists?.length) {
    //     filters.default.push(
    //         selection.pricelists
    //             .map(
    //                 ({
    //                     is_manual_code: isManualCode,
    //                     automatic_pricelist_code: automaticPricelistCode,
    //                     manual_pricelist_code: manaulPricelistCode,
    //                 }) => {
    //                     let pricelistFilter = '';
    //                     if (isManualCode) {
    //                         pricelistFilter = manaulPricelistCode ? `pricing.code:"${manaulPricelistCode}"` : '';
    //                     } else if (automaticPricelistCode) {
    //                         pricelistFilter = `pricing.code:"${automaticPricelistCode}"`;
    //                     }

    //                     return pricelistFilter;
    //                 },
    //             )
    //             .filter((f) => f),
    //     );
    // }

    return filters;
};
