import { useState } from 'react';
import styled from '@grebban/style-system-react';

import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import Text from 'components/Text';
import Button from 'components/Button';
import { type WordpressACFWysiwyg } from 'libs/wordpress/types/acf/fields';
import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';
import { type ButtonProps } from 'components/Button';
import { ProductSlider } from 'components/product/ProductSlider';
import { ProductContainerToAlgoliaProductChildren } from './ProductContainerToAlgoliaProductChildren';
import { CategoryBar } from './CategoryBar';

const Div = styled('div')``;

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
`;

const ContentWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: inherit;
`;

const SliderWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export interface ProductsPromotionComponentPropsHeading {
    text: WordpressACFWysiwyg;
    type?: 'default' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    color?: CssVariable<CssColorKey>;
}

export interface ProductsPromotionParagraphComponentProps {
    text: WordpressACFWysiwyg;
    color?: CssVariable<CssColorKey>;
}

export type SliderSectionProps = {
    label: string;
    algoliaProductSelection: {
        default: string[][];
        selected: string[][];
        dynamic: string[][];
    }; // @TODO Should be owned by Algolia/ProductsContainer?
    settings: SliderSectionSettingsProps;
};

export type SliderSectionSettingsProps = {
    maxNumberOfProducts?: number;
};

export interface PromotionSliderComponentProps extends Record<string, unknown> {
    heading?: ProductsPromotionComponentPropsHeading;
    buttons?: ButtonProps[];
    paragraph?: ProductsPromotionParagraphComponentProps;
    sections: SliderSectionProps[];
    displayLabelsBar: boolean;
}

export const PromotionSliderComponent = ({
    heading,
    buttons,
    paragraph,
    sections,
    displayLabelsBar,
}: PromotionSliderComponentProps) => {
    const [activeSection, setActiveSection] = useState<SliderSectionProps>(sections[0]);
    const maxNumberOfProducts = activeSection.settings?.maxNumberOfProducts || 12;

    return (
        <Wrapper gap={['24px', null, '32px']}>
            <ContentWrapper
                flexDirection={['', null, null, null, 'row']}
                justifyContent={['unset', null, null, null, 'space-between']}
                marginRight={['12px', null, '16px', null, '40px']}
            >
                {heading && (
                    <Wysiwyg
                        data={heading?.text}
                        TextComponent={(props) => (
                            <Text
                                width={['100%', null, null, null, '50%']}
                                maxWidth={['100%', null, null, null, '50%']}
                                textAlign="left"
                                as={heading?.type || 'h3'}
                                color={heading?.color || 'var(--text-color-default)'}
                                typography={['Content/32_130_0_500', null, null, null, 'Content/40_120_0_500']}
                                {...props}
                            />
                        )}
                    />
                )}
                {paragraph && (
                    <Div display="flex" flexDirection="column" width={['100%', null, null, null, '50%']}>
                        <Wysiwyg
                            data={paragraph?.text}
                            TextComponent={(props) => (
                                <Text
                                    width="100%"
                                    textAlign="left"
                                    $as="p"
                                    color={paragraph?.color || 'var(--text-color-default)'}
                                    typography={['Content/16_150_0_350', null, null, null, 'Content/20_140_0_350']}
                                    {...props}
                                />
                            )}
                        />
                    </Div>
                )}
            </ContentWrapper>

            <ContentWrapper
                flexDirection={['', null, 'row-reverse']}
                justifyContent={['unset', null, 'space-between']}
                marginRight={['12px', null, '16px', null, '40px']}
            >
                {buttons && (
                    <Div display="flex" flexDirection="row" justifyContent="flex-start" gap="16px">
                        {buttons.map((button) => (
                            <Button
                                key={`${button.url}`}
                                typography="UI/16_100_0_450"
                                theme={button.theme || 'primary'}
                                to={button.url}
                            >
                                {button.children}
                            </Button>
                        ))}
                    </Div>
                )}
                {displayLabelsBar && (
                    <CategoryBar
                        sections={sections.map((section) => ({
                            label: section.label,
                            active: activeSection.label === section.label,
                            callback: () => setActiveSection(section),
                        }))}
                    />
                )}
            </ContentWrapper>

            <SliderWrapper gap={['24px', null, '32px']}>
                <ProductContainerToAlgoliaProductChildren
                    productSelection={activeSection.algoliaProductSelection}
                    productsPerPage={maxNumberOfProducts}
                >
                    {({ products }) => products && <ProductSlider products={products} />}
                </ProductContainerToAlgoliaProductChildren>
            </SliderWrapper>
        </Wrapper>
    );
};
