import PropTypes from 'prop-types';
import useSaveResponse from '@sportson/core-web/hooks/useSaveResponse';
import ProductsContainer from '@sportson/core-web/containers/ProductsContainer';
// TODO: NOTE: This is a temporary solution to get the products from Algolia until ProductsContainer is TypeScripted

export const ProductContainerToAlgoliaProductChildren = ({ productSelection, productsPerPage = 4, children }) => {
    const [savedResponse, saveResponse] = useSaveResponse();
    return (
        <ProductsContainer
            filters={productSelection}
            pageSize={productsPerPage}
            render={(props) => {
                const { response } = props;
                saveResponse(response);

                return (
                    savedResponse.current.products &&
                    children({
                        products: savedResponse.current.products,
                    })
                );
            }}
        />
    );
};

ProductContainerToAlgoliaProductChildren.propTypes = {
    productSelection: PropTypes.object.isRequired,
    productsPerPage: PropTypes.number.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
