import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';

import { type WordpressACFWysiwyg } from '../types/acf/fields';
import { type WordpressParagraph } from '../types/contentSystem/elements';
import { type WordpressParagraphGroup } from '../types/contentSystem/groups';
import transformTextColor from './transformTextColor';

export interface TransformParagraphOutput {
    text: WordpressACFWysiwyg;
    color?: CssVariable<CssColorKey>;
    size?: string;
    weight?: string;
    font?: string;
}

export const transformParagraph = (input: WordpressParagraph): TransformParagraphOutput => {
    const output: TransformParagraphOutput = {
        text: input.text,
    };

    if (input.textColor && input.textColor !== 'default') {
        output.color = transformTextColor(input.textColor);
    }

    if (input.textSize && input.textSize !== 'default') {
        output.size = input.textSize;
    }

    if (input.textWeight && input.textWeight !== 'default') {
        output.weight = input.textWeight;
    }

    if (input.font && input.font !== 'default') {
        output.font = input.font;
    }

    return output;
};

export const transformParagraphGroup = (group: WordpressParagraphGroup, device: 'mobile' | 'desktop') => {
    if (device === 'mobile' && group.customizeMobile && group.mobileParagraph) {
        return transformParagraph(group.mobileParagraph);
    }
    return transformParagraph(group.desktopParagraph);
};
