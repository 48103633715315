import { type ButtonTheme } from 'config/branding/buttons';

import {
    type WordpressActionButton,
    type WordpressBaseButton,
    type WordpressLinkButton,
} from '../types/contentSystem/elements';
import { type WordpressButtonTheme } from '../types/contentSystem/settings';

const themeMap: Record<WordpressButtonTheme, ButtonTheme | undefined> = {
    default: undefined,
    primary: 'primary',
    primaryOutlined: 'primaryOutlined',
    secondary: 'secondary',
    primaryDark: 'primaryDark',
    secondaryDark: 'secondaryDark',
    tertiary: 'tertiary',
};

const transformButton = <T extends WordpressBaseButton>(button: T) => ({
    theme: themeMap[button.buttonTheme],
    label: button.label,
});

export const transformLinkButton = (button: WordpressLinkButton) => ({
    ...transformButton(button),
    link: button.link,
});
export const transformActionButton = (button: WordpressActionButton) => ({
    ...transformButton(button),
});
