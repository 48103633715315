import styled from '@grebban/style-system-react';
import BoxButton from 'components/BoxButton';
import Text from 'components/Text';

const Wrapper = styled('div')`
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
`;

const ScrollWrapper = styled('div')`
    display: flex;
    gap: 8px;
    width: 100%;
    padding-right: 32px;
    overflow: scroll;

    /* Remove native scroll */
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
        width: 0px;
    }
`;

const Gradient = styled('div')`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 32px;
    height: 100%;
    background: linear-gradient(270deg, var(--background-color-default), transparent 100%);
    pointer-events: none;
`;

const StyledText = styled(Text)``;
const StyledBoxButton = styled(BoxButton)`
    color: var(--color-neutrals-500);
    border: none;
    padding: 12px 16px;
    border: 1px solid transparent;

    &.active {
        background-color: var(--color-neutrals-100);
        color: var(--color-base-black);
        border: 1px solid var(--color-neutrals-200);
    }
`;

interface CategoryBarProps {
    sections: { label: string; active: boolean; callback: () => void }[];
}

export const CategoryBar = ({ sections }: CategoryBarProps) => (
    <Wrapper>
        <ScrollWrapper>
            {sections.map((section) => (
                <StyledBoxButton
                    key={section.label}
                    isActive={section.active}
                    className={section.active && 'active'}
                    onClick={section.callback}
                    theme="outlined"
                >
                    <StyledText
                        as="span"
                        typography="UI/16_100_0_450"
                        dangerouslySetInnerHTML={{ __html: section.label.replace(/\\/g, '') }}
                    />
                </StyledBoxButton>
            ))}
        </ScrollWrapper>
        <Gradient />
    </Wrapper>
);
