import React from 'react';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';

import {
    type WordpressFlexibleNavigation,
    type WordpressHeadingGroup,
    type WordpressParagraphGroup,
    type WordpressProductSelection,
} from 'libs/wordpress/types/contentSystem/groups';
import { transformLinkButton } from 'libs/wordpress/utils/transformButton';
import { transformHeadingGroup } from 'libs/wordpress/utils/transformHeading';
import { transformParagraphGroup } from 'libs/wordpress/utils/transformParagraph';
import { transformWordpressProductSelectionToAlgoliaParameters } from 'libs/wordpress/utils/transformWordpressProductSelectionToAlgoliaParameters';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';
import {
    PromotionSliderComponent,
    type PromotionSliderComponentProps,
} from 'content/ProductsPromotion/PromotionSlider';
import {
    type ColumnsSectionProps,
    type ColumnsSectionSettingsProps,
    PromotionColumnsComponent,
    type PromotionColumnsComponentProps,
} from 'content/ProductsPromotion/PromotionColumns';

export interface WordpressProductsPromotionBlock {
    settings: Array<'heading' | 'paragraph' | 'link'>;
    heading?: WordpressHeadingGroup;
    paragraph?: WordpressParagraphGroup;
    link?: WordpressFlexibleNavigation;
    fallbackProductSelection?: Array<any>;
    displaySectionLabel: boolean;
    sections: {
        label: string;
        productSelection: WordpressProductSelection;
    }[];
}

// TODO: Remove Record<string, unknown> when styling is fixed, correctly pass css via rest
export interface ProductsPromotionBlockProps extends Record<string, unknown> {
    data: WordpressProductsPromotionBlock;
    layout: 'products_promotion_slider' | 'products_promotion_columns';
}

export type ComponentData = PromotionColumnsComponentProps | PromotionSliderComponentProps;

const ProductsPromotionBlock: React.FunctionComponent<ProductsPromotionBlockProps> = ({ data, layout, ...rest }) => {
    const isMobile = useBreakpoint({ to: 'desktop.sm' });
    const device = isMobile ? 'mobile' : 'desktop';

    const componentData: ComponentData = {
        displayLabelsBar: data.sections.length > 1 || (data.sections.length <= 1 && data.displaySectionLabel),
        sections: [],
    };

    // Heading
    if (data.settings.includes('heading') && data.heading) {
        componentData.heading = transformHeadingGroup(data.heading, device);
    }

    // Link
    if (data.settings.includes('link') && data.link) {
        const linkType = data.link[0].layout;
        if (linkType === 'buttons') {
            componentData.buttons = data.link[0].buttons.map((button) => {
                const tButton = transformLinkButton(button.button);
                return {
                    url: tButton.link,
                    // TODO: NOTE: remove ArrowIcon when button component is updated according to design
                    children: (
                        <>
                            {tButton.label}
                            <ArrowIcon />
                        </>
                    ),
                    theme: tButton.theme,
                };
            });
        } else if (linkType === 'content') {
            componentData.contentLink = data.link[0].link;
        }
    }

    // Text
    if (data.settings.includes('paragraph') && data.paragraph) {
        componentData.paragraph = transformParagraphGroup(data.paragraph, device);
    }

    // Product Selection
    if (data?.sections) {
        data.sections.map((section) => {
            let productSelectionSettings: ColumnsSectionSettingsProps = {};

            if (
                section.productSelection[0].layout === 'norce_category' ||
                section.productSelection[0].layout === 'norce_advanced_selection'
            ) {
                productSelectionSettings = section.productSelection[0].settings;
            }

            componentData.sections.push({
                label: section.label,
                algoliaProductSelection: transformWordpressProductSelectionToAlgoliaParameters(
                    section.productSelection,
                ),
                settings: productSelectionSettings,
            });
        });
    }

    if (layout === 'products_promotion_columns') {
        const componentDataColumns: PromotionColumnsComponentProps = {
            ...componentData,
            sections: componentData.sections as ColumnsSectionProps[],
        };

        return <PromotionColumnsComponent {...componentDataColumns} {...rest} />;
    }
    if (layout === 'products_promotion_slider') {
        const componentDataSlider: PromotionSliderComponentProps = componentData;

        return <PromotionSliderComponent {...componentDataSlider} {...rest} />;
    }
    throw new Error('Invalid layout');
};

export default ProductsPromotionBlock;
