/**
 * @author Timmie Sarjanen
 * @version 1.0.1
 * @todo: Document this and no need for a class component for projects that can have hooks.
 */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Interactable from '@sportson/core-web/components/Interactable';
import InfiniteSlider from './InfiniteSlider';
import Slider from './Slider';

export default class TouchableSlider extends Component {
    slider = createRef();

    state = {
        transformOffset: null,
    };

    static propTypes = {
        isInfinite: PropTypes.bool,
        onTap: PropTypes.func,
        threshold: PropTypes.number,
    };

    static defaultProps = {
        isInfinite: false,
        onTap: null,
        threshold: 50,
    };

    onSwipe = ({ diffX }) => {
        this.setState({ transformOffset: -Math.ceil(diffX) });
    };

    onSwipeEnd = ({ absX, direction }) => {
        const slider = this.slider.current;

        this.setState({ transformOffset: null });

        if (slider) {
            const { threshold } = this.props;

            if (direction === 'right' && absX > threshold) {
                slider.slidePrev();
            } else if (direction === 'left' && absX > threshold) {
                slider.slideNext();
            }
        }
    };

    slideTo = (index) => this.slider.current && this.slider.current.slideTo(index);

    slidePrev = () => this.slider.current && this.slider.current.slidePrev();

    slideNext = () => this.slider.current && this.slider.current.slideNext();

    render() {
        const { transformOffset } = this.state;
        const { isInfinite, onTap, ...props } = this.props;

        const SliderComponent = isInfinite ? InfiniteSlider : Slider;

        return (
            <Interactable
                className="slider-component-wrapper"
                onSwipe={this.onSwipe}
                onSwipeEnd={this.onSwipeEnd}
                onTap={onTap}
            >
                <SliderComponent ref={this.slider} transformOffset={transformOffset} {...props} />
            </Interactable>
        );
    }
}
